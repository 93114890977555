import WatsonApi from "../../../backends/WatsonApi";
import {useContext, useRef} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import NotificationManager from "../../../components/notifications/NotificationManager";
import {useMachine} from "@xstate/react";
import {FetchMachine} from "../../../components/machines/FetchMachine";
import {compressImage} from "../../../utils/DataUtilities";
import styles from "./styles/FileUpload.module.scss";
import FontAwesome from "../../../components/utilities/FontAwesome";
import {isJson} from "../../../components/admin/spinup/utilities";

export type IImageAttributeTypes = "logo" | "picture" | "top_nav_logo" | "favicon" | "icon" | "backgroundImage";

type IFileUploadProps = {
    imageUrl: string,
    handleImageUrlChange: (value: string) => void,
    imageType: IImageAttributeTypes,
    label: string,
    className?: string,
}

export function getDisplayName (imageUrl) {
    if (/url\(/.test(imageUrl)) {
        // backgroundImage for floating blocks uses url("image.png") format
        const match = imageUrl.match(/url\("(.*)"\)/);
        if (match) {
            imageUrl = match[1];
        }
    }
    return imageUrl.split("/")[imageUrl.split("/").length - 1];
}

export function ImageUpload(props: IFileUploadProps) {
    const {organizationStore, modalStore} = useContext(StoreContext);
    let {imageUrl, imageType} = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [current, send] = useMachine(FetchMachine);

    async function sbSettings_imageReset() {
        send("FETCH");
        try {
            send("FULFILL");
            const client = await WatsonApi();
            await client.apis.organizations.organizations_file_upload_delete({
                organization_pk: organizationStore.currentOrganization.id,
                attribute: imageType,
            })
            props.handleImageUrlChange("");
            if (inputRef.current) {
                inputRef.current.value = '';
            }
            NotificationManager.success(`Success deleting ${imageType} image.`);
        } catch (e) {
            send("REJECT");
            NotificationManager.error(`Error deleting ${imageType} image`);
            console.error(String(e));
        }
    }

    async function sbSettings_fileInputChange(event) {
        event.stopPropagation();

        if (event.target.files.length === 0) return;

        send("FETCH");
        const files = event.target.files;

        if (files.length) {
            try {
                const file = await compressImage(files[0], 1)
                const response = await organizationStore.imageUpload(imageType, file, file.name);
                send("FULFILL");
                props.handleImageUrlChange(response.media_url);
                NotificationManager.success(`Success uploading ${imageType} image.`);
            } catch (e) {
                send("REJECT");
                if (isJson(e.response?.data)) {
                    NotificationManager.error(e.response?.body?.message, 'Image Upload Error', 4000);
                } else {
                    NotificationManager.error(e.message, undefined, 3000);
                }
            }
        }
    }

    let displayName = "";
    if (imageUrl) {
        displayName = getDisplayName(imageUrl)
    }

    let label;
    if (current.value === "PENDING") {
        label = <label htmlFor={`input-${imageType}`} className={`disabled ${styles.button}`}>
            <span className="fas fa-spinner fa-pulse"/>
            <span style={{marginLeft: "10px"}}>Saving...</span>
        </label>
    } else if (props.imageType === "favicon" || props.imageType === "icon") {
        label = <button onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            modalStore.addModal({type: `${props.imageType}Upload`, setPreviewHref: props.handleImageUrlChange});
        }} className={styles.button}>
            <FontAwesome title={props.imageUrl ? "Replace Image" : "Upload Image"} fixedWidth name={"fa-solid fa-upload"} />
        </button>
    } else {
        label = <label htmlFor={`input-${imageType}`} className={styles.button}>
            <span><FontAwesome title={props.imageUrl ? "Replace Image": "Upload Image"} fixedWidth name={"fa-solid fa-upload"} /></span>
        </label>
    }

    return <div className={styles.container}>
        <div>
            {props.label && <div className={styles.label}>{props.label}</div>}
            {props.imageUrl && <div className={styles.previewContainer}>
                <img alt={props.label} title={displayName} className={props.imageUrl ? "" : "hidden"}
                     src={props.imageUrl}
                     style={{maxHeight: "100px"}}/>
            </div>}
            <div className={styles.controls}>
                <input ref={inputRef} id={`input-${imageType}`} title={props.label} type="file" accept={'image/*'}
                       className="file-input hidden"
                       name={`input-${imageType}`} onChange={sbSettings_fileInputChange}
                       disabled={current.value === "PENDING"}/>
                {label}
                <button className={`file-delete ${imageUrl ? "" : "hidden"} ${styles.button}`}
                        onClick={sbSettings_imageReset}>
                    <FontAwesome fixedWidth name="fa-solid fa-trash" aria-label="Delete existing image"/>
                </button>
            </div>
        </div>
    </div>
}
