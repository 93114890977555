import Geocode from "./Geocoder";
import {isObservableArray, toJS} from "mobx";
import seedrandom from "seedrandom";

function nLevel(path) {
    return path.split(".").length;
}

function isDirectAncestor(ancestorPath, path) {
    return path.split(".").slice(0, path.split(".").length - 1).join(".") === ancestorPath;
}

export function isDescendantOrEq(descendantPath, currentPath) {
    return new RegExp(currentPath).test(descendantPath) && nLevel(descendantPath) >= nLevel(currentPath);
}

export const convertFlatToTree = (flatArray) => {
    if (!Array.isArray(flatArray) && !isObservableArray(flatArray)) return [];
    const placedPaths = [];
    const topNLevel = Math.min(...flatArray.map(item => nLevel(item.path)));

    function recursiveFlatToTree(items, parentPath) {
        return items
            .filter(item => nLevel(item.path) === topNLevel || (parentPath && isDirectAncestor(parentPath, item.path)))
            .map(item => {
                const children = flatArray.filter(c => isDirectAncestor(item.path, c.path));
                placedPaths.push(item.path);

                return {
                    ...item,
                    children: recursiveFlatToTree(children, item.path),
                }
            });
    }

    const tree = recursiveFlatToTree(flatArray);
    const orphans = flatArray.filter(item => {
        // this is weird logic to only limit orphans to topNLevel + 2, but it's here so we remain consistent with what's
        // currently in production. we can change it in the future if we want to but we may alter existing sites in doing so.
        return placedPaths.indexOf(item.path) === -1 && nLevel(item.path) <= topNLevel + 2;
    }).map(item => ({...item, children: []}));

    return tree.concat(orphans);
};

export function addLeadingZeros(idx) {
    return idx.toString().padStart(3, "0");
}

export const convertTreeToFlat = (treeData, basePath) => {
    const flatArray = [];

    function addToArray(item, basePath, index) {
        const paddedIndex = addLeadingZeros(index+1);
        const path = `${basePath}.${paddedIndex}`;
        flatArray.push({
            ...item,
            path,
            children: [],
        });
        if (item.children) {
            item.children.forEach((item, index) => addToArray(item, path, index));
        }
    }

    treeData.forEach((item, index) => addToArray(item, basePath, index));

    return flatArray;
};

export const normalizeLocation = (itemObj) => {
    /*
    Output Location Type:
    {
        address: String,
        city: String,
        country: String,
        zip: String,
        phone: String,
        coordinates: {
            lat: Number,
            lng: Number
        }
    }
    */
    let location;
    if ((itemObj?.json_data?.location?.address
        && itemObj.json_data.location.city
        && itemObj.json_data.location.state)
        && !itemObj?.geo_point?.coordinates) {
        Geocode(`${itemObj.json_data.location.address} ${itemObj.json_data.location.city}, ${itemObj.json_data.location.state}`, [{
            type: 'types',
            value: ['address', 'poi'],
        }, {
            type: 'country',
            value: 'us',
        }, {
            type: 'autocomplete',
            value: false,
        }]).then(res => {
            location = toJS(itemObj.json_data.location); // this is necessary to not change mobx values
            if (res.length > 0) {
                location.coordinates = {
                    lat: res[0].center[1],
                    lng: res[0].center[0],
                };
            }
        });
    } else if (itemObj?.geo_point?.coordinates) {
        location = {
            ...toJS(itemObj.json_data.location),
            coordinates: {
                lat: itemObj.geo_point.coordinates[1],
                lng: itemObj.geo_point.coordinates[0],
            },
        };
    } else if (itemObj?.json_data?.location) {
        location = toJS(itemObj.json_data.location)
    }

    return location;
};

export const getSeededRandom = arr => {
    // accepts array of items, returns a "random" seeded item based on current minute
    const date = new Date();
    const rng = seedrandom('cleversite' + date.getMinutes());
    const random = rng();
    const num = Math.floor(random * arr.length);
    return arr[num];
};

export const downloadBlob = (blob, filename = 'download') => {
    // accepts a blob and a filename, returns the link and forces click on it for automatic download.
    // Create an object URL for the blob object
    const url = URL.createObjectURL(blob);

    // Create a new anchor element
    const a = document.createElement('a');

    // Set the href and download attributes for the anchor element
    // You can optionally set other attributes like `title`, etc
    // Especially, if the anchor element will be attached to the DOM
    a.href = url;
    a.download = filename;

    // Click handler that releases the object URL after the element has been clicked, required for one-off downloads
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            if (this) this.removeEventListener('click', clickHandler);
        }, 150);
    };

    // Add the click event listener on the anchor element, for one-off downloads of blob content
    a.addEventListener('click', clickHandler, false);

    // trigger click to automatically download blob content.
    a.click();

    // Return the anchor element
    // Useful if you want a reference to the element
    // in order to attach it to the DOM or use it in some other way
    return a;
}

// "0" is truthy in javascript but falsy in php - this handles that
export function getBoolean(value) {
    return value === "0" ? false : !!value;
}

export const compressImage = async (file, maxSizeMB) => {
    const supportedType = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/bmp'].includes(file.type);

    // Check if file size is below max size. If so, return
    if (file.size <= maxSizeMB * 1048576) {
        return file;
    }
    else if (!supportedType) {
        // if the image is not supported by the compression library and larger than the max size, throw an error.
        throw new Error(`File is too large, please reduce to ${maxSizeMB}MB`)
    }
    else if (supportedType) {
        // compression library only supports these types
        const imageCompression = (await import("browser-image-compression")).default
        try {
            file = await imageCompression(file, {
                maxSizeMB,
            });
        }
        catch (e) {
            throw new Error("An unexpected error occurred during image compression.")
        }

        if (file.size <= maxSizeMB * 1048576) {
            return file;
        }
        else {
            throw new Error(`File is too large, please reduce to ${maxSizeMB}MB`)
        }
    }
    else {
        // file uploaded is not a recognized image file type
        throw new Error('File type not supported, please try uploading a .jpeg/.jpg or .png file')
    }
}

export function replaceSvgWithPng(inputString) {
    return inputString.slice(0, -4) + ".png";
}
