import {isObservableArray} from "mobx";
import {regexQuote} from "./StringUtilities";
import URI from 'urijs';
import {getConfig} from "../_configs/AppConfig";
import {OrganizationTypesWithStaffBlock} from "../types/OrganizationTypeValues";

const {publicRuntimeConfig} = getConfig();

// A utility function to check if we are on the regular SchoolBlocks app.
// Used to set `interfaceStore.isSchoolBlocks = true`.
// If tests fail when `interfaceStore.isSchoolBlocks` should be truthy, try either
// switching to this util function or importing it at the top of the component.
export function isSchoolBlocksApp(): boolean {
    return process.env.NEXT_PUBLIC_APP_CTX === "schoolblocks";
}

// A utility function to check if we are on the SchoolFeed standalone app.
export function isSchoolFeedStandalone(): boolean {
    return process.env.NEXT_PUBLIC_APP_CTX === "schoolfeed";
}

/*
main page - FLEX_GRID
my feed page - LIST_GRID (feed grid and search grid are almost the same but use different filters)
following page - LIST_GRID
groups/departments/sections pages - ALPHA_GRID
search page - LIST_GRID
staff page - ALPHA_GRID
schools page - LIST_GRID (very similar to collection grid but with different filters)
 */

export const enum gridTypesEnum {
    LIST_GRID = "LIST_GRID",
    ALPHA_GRID = "ALPHA_GRID",
    FLEX_GRID = "FLEX_GRID",
}

export const columnWidth = 304;

// subtract margin from all these values because react-grid-layout adds margin to heights
export const gridRowHeights = {
    search: (columnWidth / 2) + 23 - 14,
    person: (columnWidth / 2) + 56 - 14,
    alpha: (columnWidth / 2) + 26 - 14,
    default: (columnWidth / 2) - 14,
}

export function subOrganizationTypesByParent(parentType: OrganizationTypesWithGrids): Array<OrganizationTypesWithGrids> {
    const allowedTypes: Array<OrganizationTypesWithGrids> = [];
    switch (parentType) {
        case "district":
            allowedTypes.push("department");
            break;

        case "school":
            allowedTypes.push("group", "team");
            break;

        case "organization":
            allowedTypes.push("department", "group", "team");
            break;

        default:
            // no changes to the default
            break;
    }

    allowedTypes.push("section");
    return allowedTypes;
}

export function subOrganizationTypesForStaffBlock(parentType: OrganizationTypesWithGrids): Array<OrganizationTypesWithStaffBlock> {
    const allSubOrgs = subOrganizationTypesByParent(parentType);
    return allSubOrgs
        .filter((t: OrganizationType) =>
            OrganizationTypesWithStaffBlock.includes(t as OrganizationTypesWithStaffBlock)) as Array<OrganizationTypesWithStaffBlock>;
}

// returns district, or if there is no parent district, returns current organization
export function getDistrict(organization) {
    let district;
    if (organization.type === 'school' && organization.district && organization.parent_id !== "root") {
        district = organization.district;
    } else {
        district = organization;
    }
    return district;
}

export function makeNounPossessive(noun: string): string | null {
    if (typeof noun !== "string") return noun;

    if (noun.toLowerCase()[noun.length - 1] === "s") {
        return noun + "'";
    }
    return noun + "'s";
}

export function getCdnUrl(url: string): string {
    if (/^http/.test(url) || !url) return url;
    return publicRuntimeConfig.MEDIA_CDN_URL + (url[0] === "/" ? "" : "/") + url;
}

export function isArray(arr: any): boolean {
    return Array.isArray(arr) || isObservableArray(arr);
}

export function isNotEmptyArray(arr: any): boolean {
    return (isArray(arr) || isObservableArray(arr)) && arr.length > 0;
}

const localUrlRegex = /^[/#]/i;
const absoluteUrlRegex = /^(http[s]?|webcal):\/\//;
const emailUrlRegex = /^mailto:.+@.+\..+/;
const javascriptRegex = /^javascript:/i;
const adminLinkRegex = new RegExp(/\?spview=/);
const worldWideWebRegex = /(?!:\/\/)(www\.)/;

export function isMissingProtocol(href: string): boolean {
    return !absoluteUrlRegex.test(href) && !javascriptRegex.test(href) && !localUrlRegex.test(href) && !emailUrlRegex.test(href);
}

function getCurrentHostnameRegexes(currentFullUrl) {
    const currentHostname = currentFullUrl ? URI(currentFullUrl).hostname() : "";

    // added (=){0} to make sure it doesn't capture when we stick the current URL in a URL parameter
    const currentHostnameRegex = new RegExp(regexQuote(currentHostname), 'i');

    // negative lookbehinds break mobile safari, so this is the best I can think of for now
    const currentHostnameInUrlParamsRegex = new RegExp(`\\?(.*)${regexQuote(currentHostname)}`, 'i');

    return {currentHostnameRegex, currentHostnameInUrlParamsRegex};
}

// denotes that we are currently on a page rendered with Next, and the link is to a page also rendered with Next
export function isInternalNextLink(href: string | object, currentFullUrl: string): boolean {
    const {currentHostnameInUrlParamsRegex} = getCurrentHostnameRegexes(currentFullUrl);

    try {
        return typeof href === 'object' || (
            (/^\//.test(href) || (
                URI(href.replace(worldWideWebRegex, "")).hostname() === URI(currentFullUrl.replace(worldWideWebRegex, "")).hostname() &&
                    !currentHostnameInUrlParamsRegex.test(href) && !emailUrlRegex.test(href)) ||
                adminLinkRegex.test(href)
            )
        )
    } catch (e) {
        return false
    }
}

export function isExternalLink(href: string, currentFullUrl: string): boolean {
    const {currentHostnameRegex, currentHostnameInUrlParamsRegex} = getCurrentHostnameRegexes(currentFullUrl);
    return absoluteUrlRegex.test(href) && (!currentHostnameRegex.test(href)) || currentHostnameInUrlParamsRegex.test(href)
}

type SchoolFeedDisplayView = "content" | "notificationSettings" | "following" | "leaderboard" | "directory" |"shop"  ;

export interface ISchoolFeedUrlQueries {
    following?: "false" | "true"
    type?: ISchoolFeedContentType
    orgId?: string
    currentRangeStart?: string,
    currentRangeEnd?: string,
    currentView?: SchoolFeedDisplayView,
}

export function getQueries(fullUrl: string): { [v: string]: string } {
    return URI.parseQuery(URI(fullUrl).query());
}

export function getPaths(fullUrl: string): string[] {
    return URI(fullUrl).segment();
}

export function renderGoogleLanguage(googleCode) {
    const event = new CustomEvent('last-async-block', {
        bubbles: true,
        detail: {
            langCode: googleCode,
        },
    });
    setTimeout(() => window.dispatchEvent(event), 1000);
}

export function isDeviceIosSafari (userAgent) {
    return /(iPod|iPhone)/.exec(userAgent) && /Safari/.exec(userAgent);
}

export function isDeviceIosChrome (userAgent) {
    return /(iPod|iPhone)/.exec(userAgent) && /CriOS/.exec(userAgent);
}

export function isTrueMultiDayEvent(item, startTime, endTime): boolean {
    return item.multiDay && startTime.day() !== endTime.day()
}

export function buildCleverFeedUrl(orgId: string, type?: string) {

    let url = `/feed?orgId=${orgId}`;
    if (type) {
        url = url + `&type=${type}`
    }
    return url;
}

export function buildClaimPageURL(orgId) {
    return `/claim?orgId=${orgId}`;
}

export function buildContentItemUrl(organizationId: string, itemId: number) {
    return `/contentItem/${organizationId}/${itemId}`
}

export const reactScrollBarsCustomWidth = 20;
