import { Component } from 'react';
import PropTypes from 'prop-types';


export default class CategoryIcon extends Component {
    static propTypes = {
        alias: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { category } = this.props;

        let iconHtml = null;

        if (category.icon_url) {
            iconHtml = <img style={{width: '100%', height: '100%'}} src={category.icon_url} alt={`${category.category_name} Category Icon`} />;
        }

        return iconHtml;
    }
}
